@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700;800;900;1000&display=swap');
:root {
    // --bs-secondary-color: #999;
    // --bs-dark-rgb: 255 255 255;
    --h-height: 60px;
    --f-height: 55px;
}
:root[data-theme='light'] {
    --bg: #f6f8fa;
    --bg-main: #fff;
    --bg-scroll: #999;
    --bg-scroll-hover: #888;
    --bg-second: #f1f1f1;
    --bg-second-hover: #f0f0f0;
    --bg-second-active: #ececec;
    --bg-input: #f7f7f7;
    --bg-input-focus: #f0f0f0;
    --font-color: #111;
    --font-color-dark: #111;
    --font-color-revers: #fff;
    --font-color-gray: #858585;
    --bg-hr: #eeeeee;
    --modal-close: #ddd;
}

:root,
:root[data-theme='dark'] {
    --bg: #1a1a1a;
    --bg-main: #282828;
    --bg-scroll: rgba(255, 255, 255, 0.2);
    --bg-scroll-hover: rgba(255, 255, 255, 0.4);
    --bg-second: #1f1f1f;
    --bg-second-hover: #222222;
    --bg-second-active: #1a1a1a;
    --bg-input: #222222;
    --bg-input-focus: #222222;
    --font-color: #fff;
    --font-color-dark: #111;
    --font-color-revers: #fff;
    --font-color-gray: #999;
    --bg-hr: #353535;
    --modal-close: invert(1) grayscale(100%) brightness(200%);
}

$main-font: 'Nunito';
$main-color: #f33344;
$accent: #f33344;
$accentActive: #d42635;

$bg-submenu: #fff;
$gray: #505050;
$light-gray: #8e8e8e;
$green: #47cf6e;
$radius: 1em;
$radius-sm: 0.6em;
$radius-xs: 0.4em;
$transition: 0.3s;

$xxl-bp: 1799px;
$xl-bp: 1399px;
$lg-bp: 1199px;
$md-bp: 991px;
$sm-bp: 767px;
$xs-bp: 575px;

/* mixins */
@mixin no-appearance {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    outline: none;
}
@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
@mixin scrollCustomized(
    $width: 6px,
    $radius: 7px,
    $thumbColor: var(--bg-scroll),
    $thumbColorHover: var(--bg-scroll-hover),
    $trackColor: rgba(255, 255, 255, 0.05),
    $buttonColor: rgba(255, 255, 255, 0.1)
) {
    &::-webkit-scrollbar {
        display: block;
        width: 7px;
        cursor: pointer;
        background-color: $trackColor;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $thumbColor;
        cursor: pointer;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: $thumbColorHover;
    }

    &::-webkit-scrollbar-button:vertical:start:decrement,
    &::-webkit-scrollbar-button:vertical:end:increment,
    &::-webkit-scrollbar-button:horizontal:start:decrement,
    &::-webkit-scrollbar-button:horizontal:end:increment {
        display: none;
        background-color: $buttonColor;
        border-radius: $radius;
        width: $width;
        height: $width;
    }

    &::-webkit-scrollbar-button:vertical:start:decrement {
        border-top-left-radius: $radius;
        border-bottom-left-radius: $radius;
    }

    &::-webkit-scrollbar-button:vertical:end:increment {
        border-top-right-radius: $radius;
        border-bottom-right-radius: $radius;
    }

    &::-webkit-scrollbar-button:horizontal:start:decrement {
        border-top-left-radius: $radius;
        border-bottom-left-radius: $radius;
    }

    &::-webkit-scrollbar-button:horizontal:end:increment {
        border-top-right-radius: $radius;
        border-bottom-right-radius: $radius;
    }

    &::-webkit-scrollbar-corner {
        background-color: $trackColor;
        border-radius: $radius;
    }
}

.text-muted {
    color: var(--font-color-gray) !important;
}
a,
a:hover,
a:focus,
a:active {
    color: inherit;
    outline: none;
    text-decoration: none;
    cursor: pointer;
}

// p,
// label,
// span,
// a,
// form,
// fieldset,
// legend,
// select,
// div,
// blockquote,
// cite,
// figure,
// figcaption,
// table,
// ul,
// ol,
// dl,
// li {
//     line-height: 120%;
// }

input,
textarea,
select,
.select,
.phone-input,
.form-control {
    background-color: var(--bg-input);
    color: var(--font-color);
    border: none;
    border-radius: $radius-sm;
    height: 50px;
    padding: 5px 15px;
    width: 100%;
    line-height: normal;
    outline: none;
    display: block;
    box-shadow: none !important;
    -moz-user-select: all !important;
    -webkit-user-select: all !important;
    -webkit-touch-callout: all !important;
    -ms-user-select: all !important;
    -o-user-select: all !important;
    user-select: all !important;
    -khtml-user-select: all !important;
    &:-moz-placeholder,
    &::-moz-placeholder,
    &::-webkit-input-placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder {
        color: var(--font-color-gray);
        font-weight: 300;
    }
    &::placeholder {
        color: var(--font-color-gray);
        font-weight: 300;
    }
    &:disabled {
        background-color: var(--bg-second-hover);
        color: var(--font-color);
    }
}
textarea {
    @include scrollCustomized();
    height: auto !important;
    padding: 10px 15px !important;
    resize: none !important;
}
input {
    &[type='number'] {
        @include no-appearance();
    }
    &[type='radio'],
    &[type='checkbox'] {
        @include no-appearance();
        cursor: pointer;
        background-color: $gray;
        box-shadow: none;
        padding: 0px;
        margin: 0px;
        transition: background-color $transition;
        border: none;
    }
    &[type='radio']:checked,
    &[type='checkbox']:checked {
        background-color: $accent;
    }
    &[type='radio'] {
        border-radius: 50%;
    }
    &[type='checkbox'] {
        border-radius: $radius-xs;
    }
}
.select {
    @include no-appearance();
    padding-right: 42px !important;
    position: relative;
    -moz-user-select: none !important;
    -webkit-user-select: none !important;
    -webkit-touch-callout: none !important;
    -ms-user-select: none !important;
    -o-user-select: none !important;
    user-select: none !important;
    -khtml-user-select: none !important;
    font-size: 1rem;
    .select-label {
        -moz-user-select: none !important;
        -webkit-user-select: none !important;
        -webkit-touch-callout: none !important;
        -ms-user-select: none !important;
        -o-user-select: none !important;
        user-select: none !important;
        -khtml-user-select: none !important;
        position: absolute;
        top: -9px;
        left: 2px;
        background-color: var(--bg-main);
        padding: 0px 5px;
        font-size: 11px;
        color: #919eab !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }
}
.form-check {
    min-height: auto;
    margin-bottom: 0px;
    .form-check-label {
        line-height: 120%;
        cursor: pointer;
        font-size: 0.9em;
    }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    @include no-appearance();
}
label {
    display: block;
    span {
        flex: 1;
        margin-left: 0.6em;
    }
}
fieldset {
    legend {
        font-size: 1em;
        font-weight: 500;
    }
}
.input-group {
    flex-wrap: nowrap;
    & > *:not(:first-child) {
        margin-left: 0px !important;
    }
}

button {
    background: none;
    border: none;
    padding: unset;
    display: block;
    font-family: inherit;
    outline: none;
    color: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.btn {
    padding: 0em 1em;
    line-height: 48px;
    height: 50px;
    text-align: center;
    width: fit-content;
    font-size: 1em;
    box-sizing: border-box;
    border-radius: 60px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: $main-color;
    color: #fff;
    border: 1px solid $main-color;
    &:hover,
    &:focus,
    &:active {
        background-color: $accentActive !important;
        color: #fff !important;
        border: 1px solid $accentActive !important;
    }
    &:disabled {
        background-color: var(--bg-second-hover);
        color: var(--font-color);
        border: 1px solid var(--bg-second-hover);
    }
    &-lg {
        height: 60px;
        padding: 0.7em 1.2em;
        font-size: 1.3em;
    }
    &-secondary {
        @extend .btn;
        background-color: var(--bg-second) !important;
        color: var(--font-color) !important;
        border: 1px solid var(--bg-second) !important;
        position: relative;
        overflow: hidden;
        &:hover,
        &:focus,
        &:active {
            transition: $transition;
            color: var(--font-color) !important;
            background-color: var(--bg-second-hover) !important;
            border: 1px solid var(--bg-second-hover) !important;
        }
        &:disabled {
            background-color: var(--bg-second) !important;
            color: var(--font-color) !important;
            border: 1px solid var(--bg-second) !important;
        }
    }
    &-white {
        @extend .btn;
        background-color: var(--bg-main) !important;
        color: var(--font-color) !important;
        border: 1px solid var(--bg-main) !important;
        position: relative;
        overflow: hidden;
        &:hover,
        &:focus,
        &:active {
            transition: $transition;
            color: var(--font-color) !important;
            background-color: var(--bg-second-hover) !important;
            border: 1px solid var(--bg-second-hover) !important;
        }
        &:disabled {
            background-color: var(--bg-second) !important;
            color: var(--font-color) !important;
            border: 1px solid var(--bg-second) !important;
        }
    }
    &-input {
        @extend .btn;
        background: var(--bg-second);
        color: var(--font-color);
        border-radius: $radius-sm;
        border: 1px solid var(--bg-second);
        &:hover,
        &:focus,
        &:active {
            transition: $transition;
            background-color: var(--bg-second) !important;
            color: #fff !important;
            border: 1px solid var(--bg-second) !important;
        }
        &:disabled {
            background-color: #373737;
            color: #666;
            border: 1px solid #373737;
        }
    }
    &-primary {
        @extend .btn;
        background-color: $main-color;
        color: #fff !important;
        border: 1px solid $main-color;
        &:hover,
        &:focus,
        &:active {
            background-color: $accentActive !important;
            color: #fff !important;
            border: 1px solid $accentActive !important;
        }
        &:disabled {
            background-color: var(--bg-second-active);
            color: var(--font-color);
            border: 1px solid var(--bg-second-active);
        }
    }
    &-blue {
        @extend .btn;
        background-color: #4aaee8;
        color: #fff;
        border: 1px solid #4aaee8;
        &:hover,
        &:focus,
        &:active {
            background-color: #3ea4df !important;
            color: var(--font-color);
            border: 1px solid #4aaee8 !important;
        }
        &:disabled {
            background-color: var(--bg-second-active);
            color: var(--font-color);
            border: 1px solid var(--bg-second-active);
        }
    }
    &-fav {
        position: absolute;
        top: 1.5em;
        right: 1.5em;
        width: 1.5em;
        height: 1.5em;
        .icon {
            font-size: 1.5em;
            position: absolute;
            top: 0px;
            left: 0px;
            &-stroke {
                color: var(--font-color);
                @extend .icon;
                z-index: 1;
            }
            &-fill {
                color: $main-color;
                @extend .icon;
                z-index: 2;
                transition: $transition;
            }
        }
    }
    &-sm {
        padding: 0em 0.8em;
        line-height: 34px;
        height: 35px;
        font-size: 0.8em;
    }
}
.toggle-btns {
    width: 100%;
    background-color: var(--bg-second);
    border-radius: $radius-sm;
    display: flex;
    align-items: stretch;
    button {
        width: 50%;
        color: var(--font-color-gray);
        font-weight: 600;
        padding: 0.9em;
        border: 1px solid transparent;
        border-radius: $radius-sm;
        &.active {
            color: var(--font-color);
            border: 1px solid $main-color;
            background-color: var(--bg-main);
        }
    }
}
a svg path {
    stroke: inherit;
}
h1,
.h1 {
    font-size: 2.25em;
    font-weight: 700;
    text-align: left;
    margin-bottom: 0.5em;
    @media (max-width: $md-bp) {
        font-size: 2em;
    }
    @media (max-width: $xs-bp) {
        font-size: 1.6em;
    }
}
h2 {
    font-size: 1.75em;
    font-weight: 700;
    text-align: left;
    margin-bottom: 0.5em;
    @media (max-width: $xs-bp) {
        font-size: 1.5em;
    }
}
h3 {
    font-size: 1.25em;
    font-weight: 700;
    text-align: left;
    margin-bottom: 0px;
}
h4 {
    font-size: 1.25em;
    font-weight: 300;
    text-align: left;
    margin-bottom: 0px;
}
h5 {
    font-size: 1.1em;
    font-weight: 600;
    text-align: left;
    margin-bottom: 0px;
}
h6 {
    font-size: 0.8em;
    color: var(--font-color-gray);
}
.fs {
    &-07 {
        font-size: 0.7em;
    }
    &-08 {
        font-size: 0.8em;
    }
    &-09 {
        font-size: 0.9em;
    }
    &-10 {
        font-size: 1em;
    }
    &-11 {
        font-size: 1.1em;
    }
    &-12 {
        font-size: 1.2em;
    }
    &-13 {
        font-size: 1.3em;
    }
    &-14 {
        font-size: 1.4em;
    }
    &-15 {
        font-size: 1.5em;
    }
    &-16 {
        font-size: 1.6em;
    }
    &-17 {
        font-size: 1.7em;
    }
    &-18 {
        font-size: 1.8em;
    }
    &-19 {
        font-size: 1.9em;
    }
    &-20 {
        font-size: 2em;
    }
    &-25 {
        font-size: 2.5em;
    }
    &-30 {
        font-size: 3em;
    }
    &-35 {
        font-size: 3.5em;
    }
    &-40 {
        font-size: 4em;
    }
}
.fw {
    &-3 {
        font-weight: 300;
    }
    &-4 {
        font-weight: 300;
    }
    &-5 {
        font-weight: 500;
    }
    &-6 {
        font-weight: 600;
    }
    &-7 {
        font-weight: 700;
    }
    &-8 {
        font-weight: 800;
    }
    &-9 {
        font-weight: 900;
    }
}

.main-color {
    color: $main-color;
}
.font-color {
    color: var(--font-color);
}
.font-faded {
    color: var(--font-color-gray);
}
.light-gray {
    color: $light-gray;
}
.green {
    color: $green;
}
.opacity-0 {
    opacity: 0;
}
.opacity-1 {
    opacity: 1;
}

.bb-1 {
    border-bottom: 1px dashed currentColor;
}

table.simple {
    width: 100%;
    td {
        font-weight: 500;
        padding: 0.5em 0;
        line-height: 125%;
        &:first-child {
            color: var(--font-color-gray);
            padding-right: 1em;
        }
        &:last-child {
            color: var(--font-color);
            text-align: right;
        }
    }
}
hr,
.hr {
    display: flex;
    height: 1px;
    background: linear-gradient(90deg, transparent, var(--bg-hr) 50%, transparent);
    padding: 0 15px;
    margin: 20px 0;
}
.box {
    background: var(--bg-main);
    border-radius: $radius;
    // border: 1px solid var(--bg-second-hover);
    padding: 1em;
    .product-item {
        margin: 0px !important;
    }
}
.flex-1 {
    flex: 1;
}
.mb-6 {
    margin-bottom: 6em;
    @media (max-width: $sm-bp) {
        margin-bottom: 5em;
    }
    @media (max-width: $xs-bp) {
        margin-bottom: 4em;
    }
}
@media (max-width: $xs-bp) {
    .px-mobile-0 {
        padding-left: 0px;
        padding-right: 0px;
    }
}
.no-select {
    -moz-user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    -khtml-user-select: none;
}
.yes-select {
    -moz-user-select: text;
    -webkit-user-select: text;
    -webkit-touch-callout: text;
    -ms-user-select: text;
    -o-user-select: text;
    user-select: text;
    -khtml-user-select: text;
}
body {
    touch-action: pan-x pan-y;
    -ms-touch-action: pan-x pan-y;
    margin: 0;
    font-family: $main-font;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--bg);
    color: var(--font-color);
    font-size: 17px;
    font-weight: 400;
    @media (max-width: $lg-bp) {
        font-size: 16px;
    }
}
.line-height-100 {
    line-height: 100%;
}
.logo-img {
    border-radius: 25px;
}
.input-code {
    display: flex;
    input {
        margin-right: 15px;
        text-align: center;
        height: 50px;
        font-size: 20px;
        padding-left: 0px;
        padding-right: 0px;
        font-weight: 600;
        &:last-child {
            margin-right: 0px;
        }
    }
}
.read {
    font-weight: 400;
    font-size: 17px;
    word-wrap: break-word !important;
    white-space: break-spaces !important;
}

.book {
    &-slider {
        position: relative !important;

        .swiper-slide {
            width: fit-content;
        }
        .swiper-button {
            color: var(--main-color-text);
            @media (max-width: $lg-bp) {
                display: none;
            }
            &-disabled {
                opacity: 0;
            }
            &-prev {
                background-color: var(--bg);
                border-radius: 40px;
                width: 40px;
                height: 40px;
                top: 43%;
                left: 10px;
                color: var(--font-color);
                box-shadow: 0px 0px 12px 0px var(--bg) !important;
                &::after {
                    font-size: 15px;
                }
            }
            &-next {
                background-color: var(--bg);
                border-radius: 40px;
                width: 40px;
                height: 40px;
                top: 43%;
                right: 10px;
                color: var(--font-color);
                box-shadow: 0px 0px 12px 0px var(--bg) !important;
                &::after {
                    font-size: 15px;
                }
            }
        }
    }
    &-img {
        max-width: 230px;
        min-width: 230px;
        border-radius: $radius;
        background-color: var(--bg-second);
        // box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
    }
    &-progress {
        position: fixed;
        top: var(--h-height);
        left: 0px;
        right: 0px;
        height: 5px;
        background-color: #2c2c2c;
        border-radius: 0px;
    }
    &-bottom-container {
        height: var(--f-height);
    }
    &-bottom {
        background-color: var(--bg);
        .btn {
            width: 100%;
            max-width: 370px;
            a {
                width: 100%;
            }
        }
        @media (max-width: $sm-bp) {
            position: fixed;
            left: 0px;
            right: 0px;
            width: 100%;
            padding: 10px;
            bottom: var(--f-height);
        }
    }
}

.bookmark {
    --icon-size: 24px;
    --icon-secondary-color: rgb(77, 77, 77);
    --icon-hover-color: rgb(97, 97, 97);
    --icon-primary-color: #f33344;
    --icon-circle-border: 1px solid var(--icon-primary-color);
    --icon-circle-size: 35px;
    --icon-anmt-duration: 0.3s;

    margin: 8px;
    width: var(--icon-size);
    height: auto;
    fill: var(--icon-secondary-color);
    cursor: pointer;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    -webkit-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;

    &:hover {
        fill: var(--icon-hover-color);
    }
    &::after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        -webkit-box-shadow: 0 30px 0 -4px var(--icon-primary-color), 30px 0 0 -4px var(--icon-primary-color),
            0 -30px 0 -4px var(--icon-primary-color), -30px 0 0 -4px var(--icon-primary-color),
            -22px 22px 0 -4px var(--icon-primary-color), -22px -22px 0 -4px var(--icon-primary-color),
            22px -22px 0 -4px var(--icon-primary-color), 22px 22px 0 -4px var(--icon-primary-color);
        box-shadow: 0 30px 0 -4px var(--icon-primary-color), 30px 0 0 -4px var(--icon-primary-color),
            0 -30px 0 -4px var(--icon-primary-color), -30px 0 0 -4px var(--icon-primary-color),
            -22px 22px 0 -4px var(--icon-primary-color), -22px -22px 0 -4px var(--icon-primary-color),
            22px -22px 0 -4px var(--icon-primary-color), 22px 22px 0 -4px var(--icon-primary-color);
        border-radius: 50%;
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
    }

    &::before {
        content: '';
        position: absolute;
        border-radius: 50%;
        border: var(--icon-circle-border);
        opacity: 0;
    }
    &.active {
        fill: var(--icon-primary-color);
    }

    &.activeView {
        fill: var(--icon-primary-color);
        -webkit-animation: bookmark var(--icon-anmt-duration) forwards;
        animation: bookmark var(--icon-anmt-duration) forwards;
        -webkit-transition-delay: 0.3s;
        -o-transition-delay: 0.3s;
        transition-delay: 0.3s;

        &::after {
            -webkit-animation: circles var(--icon-anmt-duration) cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
            animation: circles var(--icon-anmt-duration) cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
            -webkit-animation-delay: var(--icon-anmt-duration);
            animation-delay: var(--icon-anmt-duration);
        }
        &::before {
            -webkit-animation: circle var(--icon-anmt-duration) cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
            animation: circle var(--icon-anmt-duration) cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
            -webkit-animation-delay: var(--icon-anmt-duration);
            animation-delay: var(--icon-anmt-duration);
        }
    }
}

@-webkit-keyframes bookmark {
    50% {
        -webkit-transform: scaleY(0.6);
        transform: scaleY(0.6);
    }

    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
    }
}

@keyframes bookmark {
    50% {
        -webkit-transform: scaleY(0.6);
        transform: scaleY(0.6);
    }

    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
    }
}

@-webkit-keyframes circle {
    from {
        width: 0;
        height: 0;
        opacity: 0;
    }

    90% {
        width: var(--icon-circle-size);
        height: var(--icon-circle-size);
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes circle {
    from {
        width: 0;
        height: 0;
        opacity: 0;
    }

    90% {
        width: var(--icon-circle-size);
        height: var(--icon-circle-size);
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@-webkit-keyframes circles {
    from {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    40% {
        opacity: 1;
    }

    to {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
        opacity: 0;
    }
}

@keyframes circles {
    from {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    40% {
        opacity: 1;
    }

    to {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
        opacity: 0;
    }
}

.search-desc {
    b {
        color: $main-color;
    }
}
.form-search {
    display: flex;
    position: relative;
    z-index: 999;
    align-items: center;
    input {
        flex: 1;
    }
}

.mobile-nav,
header {
    .badge {
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        text-align: center;
        vertical-align: middle;
        justify-content: center;
        background-color: #ff5252;
        color: #fff;
        line-height: 1.8em;
        font-weight: 600;
        font-size: 9px;
        padding: 0px;
        height: 1.8em;
        min-width: 1.8em;
        margin: -3px;
    }
}
.dropdown-divider {
    border-top: 1px solid #2c2c2c !important;
}
.dropdown-item {
    @extend .no-select;
    display: flex;
    align-items: center;
    padding: 0.5em 1.1em;
    white-space: wrap;
}
.dropdown-item.active,
.dropdown-item:active {
    background-color: $main-color;
}
.dropdown-menu-dark,
.dropdown-menu {
    @extend .custom-scroll;
    background-color: var(--bg-input);
    --bs-dropdown-color: #dee2e6;
    --bs-dropdown-bg: #343a40;
    --bs-dropdown-border-color: var(--bs-border-color-translucent);
    --bs-dropdown-box-shadow: ;
    --bs-dropdown-link-color: #dee2e6;
    --bs-dropdown-link-hover-color: #fff;
    --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
    --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
    --bs-dropdown-link-active-color: #fff;
    --bs-dropdown-link-active-bg: #0d6efd;
    --bs-dropdown-link-disabled-color: #adb5bd;
    --bs-dropdown-header-color: #adb5bd;
    max-height: 250px;
    overflow: auto;
}
.fake-header {
    height: var(--h-height);
}
header {
    background-color: var(--bg-main);
    position: fixed;
    height: var(--h-height);
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 9999 !important;
    transition: all 0.15s linear;

    &.hide {
        top: -60px;
    }
    .header-avatar {
        height: 35px;
        width: 35px;
        overflow: hidden;
        object-fit: cover;
        border-radius: 35px;
    }
    .header-img {
        height: 25px;
        width: 25px;
        overflow: hidden;
        object-fit: cover;
        border-radius: 35px;
    }
    .person .cap {
        position: absolute;
        width: 50px;
        height: 45px;
        bottom: 15px;
        right: -14px;
        object-fit: contain;
        transition: all 300ms;
    }
    .person:hover {
        .cap {
            bottom: 20px;
        }
    }
    &.h-fixed {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        display: none;
        z-index: 100;
        &.show {
            display: block;
        }
        @media (max-width: $md-bp) {
            top: var(--h-height);
        }
    }
    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .nav-link {
            font-weight: 500;
            font-size: 0.9em;
            transition: all 150ms;
            height: var(--h-height);
            align-items: center;
            justify-self: center;

            color: var(--font-color);
            &:active,
            &:focus,
            &.active,
            &:hover {
                color: $main-color;
                outline: none;
                font-size: 0.9em;
                box-shadow: none;
                font-weight: 500;
                transition: all 150ms;
                svg {
                    transition: all 150ms;
                    color: $main-color;
                }
            }
        }
    }
    .dropdown-menu {
        transform: translate(0px, 50px) !important;
        min-width: 15rem;
    }
    .dropdown-menu .account-panel {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: $radius-sm;
        background-color: rgba(0, 0, 0, 0.2);
        margin: 0.3em 0.7em;
        margin-bottom: 0.5em;
        padding: 0.7em 1em;
        &:hover {
            background-color: rgba(0, 0, 0, 0.3);
        }
    }
    .dropdown-toggle::after {
        display: none;
    }
}

main {
    min-height: calc(100vh - var(--h-height) - var(--f-height));
    padding: 2em 0;
}
.text-transfer {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; // количество строк
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.select-close {
    cursor: pointer;
    position: absolute;
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: center;
    width: 40px;
    top: 0;
    right: 0;
    &:hover {
        color: $main-color;
    }
}
.vh-100 {
    height: calc(100vh - var(--h-height)) !important;
}
.vh-100-empty {
    height: calc(100vh - (var(--h-height) + 100px)) !important;
}
.logo-text {
    color: var(--font-color);
    font-size: 18px;
}
@media (max-width: 991px) {
    .vh-100 {
        height: calc(100vh - (var(--h-height) + 50px)) !important;
    }
    main {
        padding: 1em 0;
        padding-bottom: var(--f-height);
    }
}

/* Profile */
input[type='date']::-webkit-calendar-picker-indicator,
input[type='time']::-webkit-calendar-picker-indicator,
input[type='datetime-local']::-webkit-calendar-picker-indicator {
    filter: invert(1);
}
.link {
    font-size: 17px;
    padding: 12px 25px;
    &:hover {
        background-color: var(--bg-second-hover);
    }
}
.profile-avatar {
    position: relative;
    overflow: hidden;
    height: 60px;
    width: 60px;
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 80px;
    }
}
.profile-card {
    background-image: url('../images/card-bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: $radius;
    color: var(--font-color-revers);
    padding: 15px;
    overflow: 'hidden';
    background-color: $main-color;
}
.profile-verified {
    width: 20px;
    height: 20px;
}
.profile-about {
    font-size: 0.9em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-clamp: 1;
    max-width: 100%;
}

/* Account Menu */
.account-info {
    min-height: 100%;
}
.account {
    &-nav {
        position: sticky;
        top: 95px;
        z-index: 8888;

        @media (max-width: 991px) {
            padding: 0px;
        }

        ul {
            list-style: none;
            padding-left: 0px;

            li {
                a,
                button {
                    display: inline-flex;
                    align-items: center;
                    width: auto;
                    padding: 0.5em 1em;
                    position: relative;
                    overflow: hidden;
                    font-size: 1em;
                    margin-bottom: 0.3em;
                    text-align: left;
                    border-radius: $radius-sm;

                    @media (max-width: 991px) {
                        font-size: 0.9em;
                    }

                    @media (max-width: 575px) {
                        padding: 0.8em;
                    }

                    &:hover {
                        background-color: rgba(255, 255, 255, 0.02) !important;
                    }

                    &.active {
                        color: $main-color;
                        background-color: var(--bg-second);
                    }

                    svg {
                        color: inherit;
                    }
                    svg polyline {
                        stroke: inherit;
                    }
                }
            }
        }

        .logout::after {
            display: none;
        }
    }

    @media (max-width: 991px) {
        .box-2 {
            box-shadow: none;
            border-radius: unset;
        }
    }
}

.tag {
    background-color: #7e7e7e1a !important;
    color: var(--font-color);
    padding: 2px 6px;
    border-radius: 5px;
    margin-right: 7px;
    margin-bottom: 7px;
}
.tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}
.price {
    span {
        line-height: 90%;
        align-self: center;
        display: flex;
    }
}
.product-item {
    position: relative;
    margin-bottom: 1em;
    transition: all;
    transition-duration: 250ms;
    &:active {
        opacity: 0.85;
    }
    .type,
    .age {
        font-size: 11px;
        height: 18px;
        line-height: 100%;
        min-width: 18px;
        padding: 3px;
        border-radius: 4px;
    }
    img,
    div.img {
        position: relative;
        height: 13vw;
        width: 100%;
        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.03);
        object-fit: cover;
        object-position: center;
        border-radius: $radius-sm;
        margin-bottom: 0.3em;

        @media (max-width: $xxl-bp) {
            height: 13vw;
        }
        @media (max-width: $xl-bp) {
            height: 17vw;
        }
        @media (max-width: $lg-bp) {
            height: 22vw;
        }
        @media (max-width: $md-bp) {
            height: 32vw;
        }
        @media (max-width: $sm-bp) {
            height: 46vw;
        }
        @media (max-width: $xs-bp) {
            height: 46vw;
        }
    }
    figcaption {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size: 13px;
        line-height: 1.5em;
        height: 3em;
    }

    .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: $lg-bp) {
            font-size: 13px;
        }
        @media (max-width: $xs-bp) {
            flex-direction: column-reverse;
            & > * {
                width: 100%;
            }
        }
    }
}

.notification-item {
    position: relative;
    margin-bottom: 1.5em;
    img,
    .img {
        height: 55px;
        width: 55px;
        object-fit: contain;
        object-position: center;
        border-radius: $radius-xs;
    }
    figcaption {
        min-height: 60px;
    }
}
.transaction-item {
    position: relative;
    margin-bottom: 1.5em;
    img,
    div.img {
        height: 50px;
        width: 50px;
        object-fit: contain;
        object-position: center;
    }
}

.border-top {
    border-color: $bg-submenu !important;
}

footer {
    padding: 1.5em 0;
    padding-bottom: 1em;
    background-color: var(--bg-main);
    font-size: 0.9em;
    .dev {
        color: $light-gray;
        font-weight: 500;
        font-size: 0.8em;
        .color {
            color: #b2ff59;
            font-weight: 600;
        }
    }
}
.footer-avatar {
    height: 35px;
    width: 35px;
    overflow: hidden;
    border-radius: 35px;
}
.mobile-nav {
    background: var(--bg-main);
    height: var(--f-height);
    @media (max-width: $md-bp) {
        position: fixed;
        bottom: 0px;
        left: 0px;
        width: 100%;
        z-index: 100;
        border-radius: $radius $radius 0 0;
    }

    @media (min-width: 992px) {
        display: none;
    }
    width: 100%;
    ul {
        height: 100%;
        width: 100%;
        display: flex;
        margin: 0;
        justify-content: space-between;
        align-items: center;
        li {
            a {
                @include flex-center();
                font-size: 1.5em;
                width: 1.9em;
                height: 1.9em;
                position: relative;
                transition: all;
                transition-duration: 250ms;
                &:active {
                    opacity: 0.85;
                }
                &.active {
                    color: $main-color;
                }
            }
        }
    }
}
.empty {
    svg {
        max-width: 300px;
        max-height: 300px;
        path,
        circle {
            color: var(--main-color) !important;
        }
    }
    &.mini {
        min-height: auto !important;
        svg {
            max-width: 230px;
            max-height: 230px;
        }
    }
}

.modal {
    z-index: 99999;
    padding-left: 0px !important;
    &-backdrop {
        z-index: 99999;
    }
    &-dialog {
        @media (max-width: $xs-bp) {
            margin: 0px !important;
            width: 100%;
        }
    }
    &-content {
        background-color: var(--bg-main);
        border-radius: $radius;
        @media (max-width: $xs-bp) {
            height: 100%;
        }
    }
    &-footer {
        border-color: var(--bg-second);
    }
    &-header {
        border-bottom: 1px solid var(--bg-second);
        padding: 1.2em 1.6em;
        position: relative;

        @media (max-width: $xs-bp) {
            padding: 1em;
        }
    }
    &-body {
        position: relative;
        padding: 1.6em;
        @media (max-width: $xs-bp) {
            padding: 1em;
        }
    }
}

.images {
    margin-left: -15px;
    margin-right: -15px;
}
.chapter-comment {
    position: relative;
    padding: 15px;
    background-color: var(--bg-second);
    border-radius: $radius-sm;
    &::before {
        content: '';
        border: solid transparent;
        position: absolute;
        left: 15px;
        bottom: 100%;
        border-bottom-color: var(--bg-second);
        border-width: 8px;
        margin-left: 0;
    }
}
.chapter-item {
    padding: 10px;
    background-color: var(--bg-second);
    color: var(--font-color);
    border-radius: $radius-sm;
    margin-bottom: 15px;
    font-size: 14px;
    &:hover {
        background-color: var(--bg-second-hover);
    }
    &:active {
        background-color: var(--bg-second-active);
    }
}
.btn-close {
    filter: var(--modal-close);
    &:focus,
    &:active {
        outline: none;
        box-shadow: none;
    }
}
.modal-header .btn-close {
    position: absolute;
    right: -40px;
    top: 15px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    padding: 10px !important;
    font-size: 12px;
    @media (max-width: $sm-bp) {
        position: relative !important;
        right: 0px;
        top: 0px;
        background-color: transparent;
        border-radius: 0px;
    }
}
.offcanvas-backdrop {
    z-index: 9999 !important;
}
.offcanvas {
    background-color: var(--bg-main);
    z-index: 99999 !important;
    color: var(--font-color);
}
.mobile-menu-left {
    a {
        display: block;
        color: var(--font-color);
        transition: $transition;
        margin-bottom: 15px;
        &.active {
            color: $main-color;
        }
    }
}

.phone-input:focus,
.form-control:focus {
    background-color: var(--bg-input-focus);
    color: var(--font-color);
    box-shadow: none;
}

.loader {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 99;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    background-color: var(--bg);
}

.custom-scroll {
    @include scrollCustomized();
}

.text-danger {
    color: #ff5252 !important;
}
.text-dark {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}
.custom-table {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
    background-color: var(--bg-main);
    color: #fff !important;
    border-radius: 1em;
    border: none;
    overflow: hidden;
    &.lite {
        box-shadow: none !important;
    }
    .item {
        border-bottom: 1px solid var(--bg-second);
    }
    .card-footer:last-child {
        padding: 15px;
        border: none;
        background-color: var(--bg-main);
    }
    .item:last-child {
        border: none;
    }
    .col,
    [class^='col-'] {
        padding: 12px;
        align-items: center;
        display: flex;
        font-size: 13px;
    }
    &.lite .head,
    .head {
        background-color: var(--bg-main);
        padding: 5px;
        font-weight: 600;
        border-top-left-radius: 1em;
        border-top-right-radius: 1em;
        border-bottom: none;
        .col {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .light {
        background-color: transparent !important;
        border-bottom: 1px solid $gray !important;
    }

    a svg {
        color: $bg-submenu;
    }
    .form-check-inpu:checked .row {
        background-color: $bg-submenu;
    }
}

.pagination {
    display: flex;
    align-items: center;
    list-style: none;

    li {
        position: relative;
        width: 50px;
        height: 50px;
        border: 1px solid $accent;
        background: transparent;
        cursor: pointer;

        @media (max-width: $xs-bp) {
            width: 45px;
            height: 45px;
        }

        &:hover {
            background: rgba($gray, 0.3);
        }

        &.selected {
            background-color: $accent;

            a {
                color: #fff;
            }
        }
    }

    .previous {
        border-radius: 14px 0 0 14px;

        &::before {
            content: '<';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .next {
        border-radius: 0 14px 14px 0;

        &::before {
            content: '>';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.min-h-30 {
    min-height: 30vh !important;
}

.w-fit {
    cursor: pointer;
    width: fit-content !important;
}
.form-text {
    display: block;
    margin-top: 0.6rem !important;
    color: var(--font-color-gray);
}
.app-link {
    padding: 10px 15px;
    border-radius: 8px;
    background-color: var(--bg-second);
    transition: all;
    transition-duration: 250ms;
    svg {
        fill: var(--font-color-revers);
    }
    &:active {
        opacity: 0.85;
    }
}
.text-success {
    color: $green !important;
}
.bg-success {
    background-color: #33a555 !important;
}
.notification-container {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999999;
    width: 320px;
    top: 65px;
    padding: 0px 15px;
    max-height: calc(100% - 30px);
}

.notification {
    box-sizing: border-box;
    padding: 13px 20px;
    border-radius: 4px;
    border-left: 3px solid transparent;
    color: #000;
    font-size: 1em;
    line-height: 1.2em;
    font-weight: bold;
    background-color: $bg-submenu;
    cursor: pointer;
    font-size: 0.9em;
    position: relative;
    margin-bottom: 15px;
}

.notification .title {
    font-size: 1em;
    line-height: 1.2em;
    font-weight: bold;
    margin: 0 0 5px 0;
}

.notification:hover,
.notification:focus {
    opacity: 1;
}

.notification-enter {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
}

.notification-enter.notification-enter-active {
    visibility: visible;
    transform: translate3d(0, 0, 0);
    transition: all 0.4s;
}

.notification-exit {
    visibility: visible;
    transform: translate3d(0, 0, 0);
}

.notification-exit.notification-exit-active {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
    transition: all 0.4s;
}

.notification-info {
    color: #2f96b4;
    border-left-color: #2f96b4;
}

.notification-success {
    color: #36b37e;
    border-left-color: #36b37e;
}

.notification-warning {
    color: #f89406;
    border-left-color: #f89406;
}

.notification-error {
    color: #bd362f;
    border-left-color: #bd362f;
}
.required {
    color: $main-color !important;
}

.badge {
    font-weight: 300;
}
.bg-primary {
    background-color: $main-color !important;
}
.mobile-bottom-padding {
    padding-bottom: 60px;
}
.user-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--bg-second);
    border-radius: $radius-sm;
    img {
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 50px;
    }
}
// Главная
.header-home-fon {
    padding: 4vh 0px;
    color: var(--font-color);
}
.home-bg-red {
    background-color: #a5657a !important;
}
.home-bg-yellow {
    background-color: #58784d !important;
}
.header-home-fon h1 {
    font-size: 35px;
    line-height: 100%;
    margin-bottom: 20px;
}
.header-home-box {
    max-width: 700px;
    display: block;
}
.header-home-box .button {
    margin-right: 10px;
}
.header-home-fon p {
    font-size: 18px;
    margin-bottom: 3%;
}
.home-box-2 {
    padding: 30px 0px;
}
.home-box-gray {
    background-color: var(--bg-second);
}
.home-box-card-info {
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 8%);
    background-color: #222;
    color: var(--font-color-revers);
    h4 {
        font-size: 22px !important;
        margin-bottom: 10px;
    }
    p {
        font-size: 15px !important;
    }
}
.status {
    position: absolute;
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    right: 15px;
    top: 15px;
    background: #40e66f;

    &.success:before {
        background: #40e66f;
    }

    &:before {
        content: '';
        display: block;
        position: absolute;
        left: -5px;
        top: -5px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        animation: pulse 2s infinite ease-in;
    }
}

@keyframes pulse {
    from {
        transform: scale(0.5);
        opacity: 0.7;
    }

    to {
        transform: scale(1.4);
        opacity: 0;
    }
}
.comment-last-box,
.comment-box {
    // background-image: url('../images/chat.png');
    // background-position: center;
    // background-size: contain;
    // background-attachment: fixed;
    overflow: hidden;
    border-radius: 20px;
    padding: 15px;
    // background-color: var(--bg-main);
}
.comment-box {
    @media (max-width: $xs-bp) {
        margin: -15px;
        border-radius: 0px;
    }
}
.avatar-min {
    img {
        width: 35px;
        height: 35px;
        object-fit: cover;
        border-radius: 100%;
    }
}
.chat {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    overflow-y: hidden;
    overflow-x: hidden;
    .answer {
        opacity: 0;
        animation: show 250ms forwards;
        padding: 10px;
        border-radius: 6px;
        background-color: #222;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .text {
            display: flex;
            align-items: center;
            font-size: 13px;
            line-height: 20px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }
    .avatar {
        width: 35px;
        height: 35px;
        object-fit: cover;
        border-radius: 100%;
    }
    .chat-body {
        max-height: calc(100vh - var(--h-height) - 210px);
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        padding-right: 15px;
        @media (max-width: $xs-bp) {
            max-height: calc(100vh - 245px);
        }
    }
    .comment-item .text {
        padding: 10px;
        border-radius: 10px;
        background-color: var(--bg-second);
        max-width: 290px;
        .answer {
            padding: 6px 8px;
            border-radius: 4px;
            border-left: 3px solid $main-color;
            background-color: #ff767615;
            display: flex;
            flex-direction: column;
            align-items: normal;
            justify-content: normal;
            font-size: 12px;
            line-height: 16px;
            margin-top: 3px;
            margin-bottom: 5px;
            .answer-text {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
    }
}
@keyframes show {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.comment-box {
    .chat-body {
        height: 100vh;
    }
}
.comment-last-box {
    .chat-body {
        overflow-y: hidden;
    }
}

input[type='range']::-webkit-slider-thumb {
    background: $main-color !important;
    outline: none !important;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 1) !important;
}
input[type='range']::-webkit-slider-runnable-track {
    background: var(--bg-second) !important;
}
input[type='range']::-moz-range-thumb {
    background: $main-color !important;
    outline: none !important;
}
input[type='range']::-ms-thumb {
    background: $main-color !important;
    outline: none !important;
}
input[type='range']::-ms-track {
    background: $bg-submenu !important;
}
.color-item {
    height: 45px;
    flex: 1;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    border: 3px solid transparent;
    text-align: center;
    align-self: center;
    margin: 0px 5px;

    &-1 {
        background-color: #fff;
    }
    &-2 {
        background-color: #ddd;
    }
    &-3 {
        background-color: #999;
    }
    &-4 {
        background-color: #888;
    }
    &-5 {
        background-color: #777;
    }
    &-6 {
        background-color: #111;
    }
    &.active {
        border-color: $green !important;
    }
}
.upload-box {
    position: relative;
    background-color: rgba(0, 0, 0, 0.03);
    border-radius: $radius-sm;
    padding: 20px;
    height: 100%;
    border: 1px dashed #919eab52;
    &.active {
        background-color: rgba(0, 0, 0, 0.1);
    }
    &.full {
        border: none !important;
        border-radius: 0px !important;
    }
    .tool {
        -webkit-box-shadow: 0px -82px 34px -64px rgba(0, 0, 0, 0.7) inset;
        -moz-box-shadow: 0px -82px 34px -64px rgba(0, 0, 0, 0.7) inset;
        box-shadow: 0px -82px 34px -64px rgba(0, 0, 0, 0.7) inset;
        height: 50px;
        align-items: center;
        a {
            width: 40px;
            height: 40px;
            border-radius: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(255, 255, 255, 0.1);
            transition: all 0.2s;
            &:hover {
                background-color: rgba(255, 255, 255, 0.3);
            }
        }
    }
}
.edit-avatar {
    display: flex;
    position: relative;
    overflow: hidden;
    border-radius: 100%;
    transition: all;
    transition-duration: 250ms;
    &:active {
        opacity: 0.85;
    }
    .img {
        width: 70px;
        height: 70px;
        overflow: hidden;
        border-radius: 100%;
    }
    .upload {
        opacity: 0;
        position: absolute;
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        z-index: 10;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.6);
        transition: all;
        transition-duration: 300ms;
    }
    &:hover .upload {
        opacity: 1;
    }
}
.check-avatar {
    position: relative;
    border-radius: 100%;
    .img {
        width: 60px;
        height: 60px;
        overflow: hidden;
        border-radius: 100%;
    }
    &.active::after {
        content: '';
        position: absolute;
        left: 0px;
        right: 0px;
        box-shadow: inset 0 0 0 3px #25bf58;
        border-radius: 100%;
        top: 0px;
        bottom: 0px;
        z-index: 10;
        background-color: rgba(0, 0, 0, 0.6);
    }
    &.active::before {
        content: '✓';
        text-align: center;
        font-size: 11px;
        line-height: 22px;
        position: absolute;
        right: 5px;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        bottom: -5px;
        z-index: 20;
        background-color: #25bf58;
    }
}
.telegram-connect {
    .img img {
        max-width: 65px;
    }
    @media (max-width: 900px) {
        .img {
            display: none;
        }
    }
}
.info-item {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    padding: 8px;
}
.info-item-bottom {
    position: absolute;
    bottom: 0px;
    left: 0px;
    padding: 2px 8px;
    z-index: 100;
}
.age {
    display: inline-flex;
    position: relative;
    height: 23px;
    min-width: 20px;
    padding: 4px;
    border-radius: 6px;
    z-index: 100;
    flex-direction: row;
    align-items: center;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    background-color: #25bf58;

    &-16 {
        background-color: #ff9100;
    }
    &-18 {
        background-color: #ee4b55;
    }
    &-21 {
        background-color: #222;
    }
}
.type {
    display: inline-flex;
    position: relative;
    margin-right: 5px;
    height: 23px;
    min-width: 20px;
    padding: 4px;
    border-radius: 6px;
    z-index: 100;
    flex-direction: row;
    align-items: center;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    background-color: #647bf8;

    &.manhwa {
        background-color: #ff9100;
    }
}
@media (max-width: 800px) {
    .header-home-fon {
        padding: 40px 0;
    }
    .header-home-fon h1 {
        font-size: 40px;
    }
    .header-home-fon p {
        font-size: 17px;
    }
}
@-webkit-keyframes pulsing {
    0% {
        top: 0px;
    }
    50% {
        top: -15px;
    }
    100% {
        top: 0px;
    }
}
@keyframes pulsing {
    0% {
        top: 0px;
    }
    50% {
        top: -15px;
    }
    100% {
        top: 0px;
    }
}

.sun {
    transition: 300ms;
    svg {
        color: #ffcd6f !important;
    }
    &:hover {
        color: #ffcd6f !important;
    }
}
.moon {
    transition: 300ms;
    svg {
        color: #6fb9ff !important;
    }
    &:hover {
        color: #6fb9ff !important;
    }
}
.profile-menu {
    flex-direction: column;
    .nav-item {
        a {
            width: 100%;
            padding: 0em 0.9em;
            height: 45px;
            display: flex;
            align-items: center;
            font-size: 0.9em;
            box-sizing: border-box;
            border-radius: 12px;
            font-weight: 500;
            color: var(--font-color);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &.active {
                background-color: var(--bg-second);
            }
        }
    }
    @media (max-width: $sm-bp) {
        flex-direction: row;
    }
}

.session {
    display: flex;
    align-items: center;
    padding: 0.7em 0.8em;
    border-radius: $radius;
    margin-bottom: 1rem;
    background-color: var(--bg-input);
    &:hover {
        background-color: var(--bg-input-focus);
    }
}

.icon-hover {
    padding: 8px;
    background-color: transparent;
    border-radius: 100%;
    transition: all 0.2s;
    &:hover {
        background-color: var(--bg-second);
    }
}

.circle {
    display: flex;
    height: 80px;
    width: 80px;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: var(--bg-second);
}

.popover {
    // color: var(--font-color-dark) !important;
    --bs-popover-bg: var(--bg-input);
    background-color: var(--bg-input);
}

.upload-info {
    top: 10px;
    right: 10px;
}

.was-validated .form-control:valid,
.form-control.is-valid {
    border-color: #47cf6e !important;
    background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%20height%3D%22200px%22%20width%3D%22200px%22%3E%3Cpath%20fill%3D%22none%22%20stroke%3D%22%2347cf6e%22%20stroke-linecap%3D%22square%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%2244%22%20d%3D%22M416%20128%20192%20384l-96-96%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    padding-right: 20px !important;
}
.upload-box-img {
    object-fit: cover;
    pointer-events: none;
    user-select: none;
    width: 100%;
    height: 100%;
    &.multiple {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.top-header {
    top: calc(var(--h-height) + 15px);
}
.book-image {
    padding: 0px;
    width: 230px;
    height: 348px;
    overflow: hidden;
}
.star {
    &.box {
        padding: 10px 12px;
        border-radius: $radius-sm;
        &:hover {
            opacity: 0.8;
        }
    }
    --icon-primary-color: gold;
    svg {
        fill: var(--icon-primary-color) !important;
    }
}
.hover-star {
    cursor: pointer;
    color: gold;
}
